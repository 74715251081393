@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/mixins/breakpoints";
@import "../../variables";
@import "../../../utilities/shapes";

.product-tile-pd {
    font-family: $product-font;

    .product-tile-image {
        font-size: 1em;

        .quick-shop {
            background-color: $product-tile-quick-shop-background-color;
            color: $product-tile-quick-shop-text-color;
            font-weight: $product-tile-quick-shop-font-weight;
            box-shadow: 0 0 0.25em $product-quick-shop-box-shadow-color;
            position: relative;
            border-radius: 0;
        }

        .product-tile-component-figure {
            width: 100%;
        }

        .product-tile-component-image {
            width: 100%;
        }
    }

    .product-tile-image .quick-shop {
        display: none;
    }

    // DO NOT display quickview for now since we have not yet implemented the feature.
    //.product-tile-image:hover .quick-shop {
    //    margin: -2.875em 1em 0.5em;
    //
    //    @include media-breakpoint-up(lg) {
    //        display: block;
    //    }
    //}

    .product-tile-body {
        @include media-breakpoint-up(md) {
            font-size: 1em;
            padding: 0.625em 1em;
        }

        @include media-breakpoint-down(sm) {
            font-size: 0.75em;
            padding: 0.3125em 0.5em;
        }

        .product-name-link {
            color: $product-name-color;
        }

        .product-price {
            font-weight: $price-font-weight;
            color: $price-color;

            .strike-through {
                text-decoration: line-through;
                color: $strike-through-price-color;
            }

            .sales.reduced-price {
                color: $reduced-price-color;
            }
        }

        .product-ratings {
            .rating-number {
                font-size: 0.75em;
                font-weight: $product-ratings-weight;
                color: $product-ratings-number-color;
            }
        }

        .color-swatches {
            @include media-breakpoint-up(md) {
                padding-top: 0.3125em;
            }

            @include media-breakpoint-down(sm) {
                padding-top: 0.1875em;
            }

            .product-tile-color-label {
                cursor: pointer;
                font-size: 1em;

                @include media-breakpoint-down(md) {
                    font-size: 0.9375em;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 0.8125em;
                }
            }

            .swatches-ul {
                list-style: none;
                padding: 0;

                .swatch-li {
                    background-repeat: no-repeat;
                    background-position: 25%;
                    margin-right: 0.75em;
                    display: inline-block;

                    .swatch-button-li {
                        border-radius: 50%;
                        width: 1.25em;
                        height: 1.25em;
                        border: 0.1875em solid white;
                        padding: 0;
                        box-shadow: 0 0 0 0.125em $product-swatch-shadow-color;
                        box-sizing: content-box;
                    }
                }

                .swatch-li:nth-child(1) {
                    .swatch-button-li {
                        box-shadow: 0 0 0 0.125em
                            $product-swatch-shadow-active-color;
                    }
                }

                .more-swatches-li,
                .more-swatches-li-sm {
                    display: inline-block;
                }

                @include media-breakpoint-down(sm) {
                    .swatch-li:nth-child(4),
                    .swatch-li:nth-child(5) {
                        display: none;
                    }

                    .more-swatches-li {
                        display: none;
                    }

                    .more-swatches-li-sm {
                        display: inline;
                    }
                }

                .more-swatches,
                .more-swatches-sm {
                    font-size: 1em;
                    font-weight: 300;
                    color: $product-tile-more-swatch-color;
                    vertical-align: bottom;
                }

                @include media-breakpoint-up(md) {
                    .more-swatches-li {
                        display: inline;
                    }

                    .more-swatches-li-sm {
                        display: none;
                    }
                }
            }
        }
    }
}
